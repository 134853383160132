import { HttpClient } from '@angular/common/http';
import { BaseHttpProvider } from 'src/app/base/base.http.provider';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ApplicationService extends BaseHttpProvider {
    constructor(http) {
        super(http, '/api/applications');
    }
    getApplications(query) {
        if (query) {
            const searchQueryEncoded = query ? encodeURIComponent(query) : '';
            return this.http.get(this.url + '?query=' + searchQueryEncoded);
        }
        else {
            return this.http.get(this.url);
        }
    }
    getApplicationByAppId(appId) {
        return this.http.get(this.url + `/${appId}`);
    }
    createApplication(data) {
        return this.http.post(this.url, data);
    }
    addNewClientSecret(applicationId, body) {
        return this.http.patch(`${this.url}/${applicationId}`, body);
    }
    deleteClientSecret(applicationId, keyId) {
        return this.http.delete(`${this.url}/${applicationId}/clientCredentials/${keyId}`);
    }
}
ApplicationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApplicationService_Factory() { return new ApplicationService(i0.ɵɵinject(i1.HttpClient)); }, token: ApplicationService, providedIn: "root" });
